import React, { useMemo } from "react";
import type { FC, ReactNode } from "react";
import { createContext, useEffect, useReducer, useState } from "react";
import { useMutation } from "react-query";
import { HTTPError } from "ky";
import { KyInstance } from "ky/distribution/types/ky";
import { AuthInfo } from "pusher-js";

import ky from "src/common/ky";
import {
  GetVisitorTokenRequest,
  GetVisitorTokenResponse,
} from "src/common/types";
import config from "src/common/config";
import pusher, { PusherAuthResponse } from "src/common/pusher";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isFetching: boolean;
  token: string | null;
  location: GeolocationPosition | null;
  errorMessage: string | null;
}

interface AuthContextValue extends State {
  resetLocation: () => Promise<string | null>;
  authenticatedKy: KyInstance;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
    token: string | null;
  };
};

type AuthenticateAction = {
  type: "AUTHENTICATE";
  payload: {
    token: string;
  };
};

type ErrorAction = {
  type: "ERROR";
  payload: {
    errorMessage: string;
  };
};

type SetLocationAction = {
  type: "SET_LOCATION";
  payload: {
    location: GeolocationPosition;
  };
};

type Action =
  | InitializeAction
  | AuthenticateAction
  | ErrorAction
  | SetLocationAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isFetching: false,
  token: null,
  errorMessage: null,
  location: null,
};

const handlers: { [key: string]: (state: State, action: Action) => State } = {
  INITIALIZE: (state: State, action: Action): State => {
    const { isAuthenticated, token } = (action as InitializeAction).payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      errorMessage: null,
      token,
    };
  },
  AUTHENTICATE: (state: State, action: Action): State => {
    const { token } = (action as AuthenticateAction).payload;

    return {
      ...state,
      isAuthenticated: true,
      errorMessage: null,
      token,
    };
  },
  ERROR: (state: State, action: Action): State => {
    const { errorMessage } = (action as ErrorAction).payload;

    return {
      ...state,
      isAuthenticated: false,
      errorMessage,
    };
  },
  SET_LOCATION: (state: State, action: Action): State => {
    const { location } = (action as SetLocationAction).payload;

    return {
      ...state,
      location,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  authenticatedKy: ky,
  resetLocation: () => Promise.resolve(null),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [initialized, setInitialized] = useState(false);

  const getVisitorToken = useMutation<
    GetVisitorTokenResponse,
    HTTPError,
    GetVisitorTokenRequest
  >(async (values) => {
    return ky
      .post("telephone-entry/property/visitor/auth", {
        json: values,
      })
      .json<GetVisitorTokenResponse>();
  }, {});

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      const visitorToken = state.token;
      if (visitorToken) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            token: visitorToken,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            token: null,
          },
        });
      }
      setInitialized(true);
    };

    if (!getVisitorToken.isLoading && !initialized) {
      initialize();
    }
  }, [initialized]);

  const _resetLocation = async (): Promise<string | null> => {
    let location = null;
    try {
      location = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      dispatch({
        type: "SET_LOCATION",
        payload: {
          location,
        },
      });
    } catch (error) {
      if (error instanceof GeolocationPositionError && error.code === 1) {
        console.log("Location permission denied");
      }
    }

    // Always reauthenticate when this happens
    return _authenticate(location);
  };

  const _authenticate = async (
    location: GeolocationPosition | null
  ): Promise<string | null> => {
    try {
      if (!location) {
        dispatch({
          type: "ERROR",
          payload: {
            errorMessage: "Could not determine location",
          },
        });
        return null;
      }

      const json = await getVisitorToken.mutateAsync({
        lat: location.coords.latitude,
        long: location.coords.longitude,
      });

      dispatch({
        type: "AUTHENTICATE",
        payload: {
          token: json.token,
        },
      });
      return json.token;
    } catch (error) {
      console.log("error", error);
      if (error instanceof HTTPError) {
        const jsonError = await error.response.json();

        dispatch({
          type: "ERROR",
          payload: {
            errorMessage: jsonError.message,
          },
        });
      }
    }
    return null;
  };

  const authenticatedKy = useMemo(
    () =>
      ky.create({
        prefixUrl: config.apiUrl,
        hooks: {
          beforeRequest: [
            async (request) => {
              let token = state.token;

              if (!token) {
                if (!state.location) {
                  token = await _resetLocation();
                } else {
                  token = await _authenticate(state.location);
                }
              }
              request.headers.set("Authorization", `Bearer ${token}`);
              request.headers.set("x-client", "virtual-intercom");
            },
          ],
        },
      }),
    [state.location, state.token]
  );

  useEffect(() => {
    pusher.config.authorizer = (channel) => ({
      authorize: async (socket_id, callback) => {
        try {
          const response = await authenticatedKy
            .post("telephone-entry/pusher/visitor/auth", {
              json: {
                channel_name: channel.name,
                socket_id,
              },
            })
            .json<PusherAuthResponse>();

          callback(false, response);
        } catch (e) {
          console.error(e);
          callback(true, {} as AuthInfo);
        }
      },
    });
  }, [authenticatedKy]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        // TODO introduce minimum delay
        isFetching: getVisitorToken.isLoading,
        resetLocation: _resetLocation,
        authenticatedKy,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
